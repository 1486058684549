<template>
    <div class="workspace__body">
        <page-header :title="user.name" icon="user-secret" :navlinks="navlinks" />
        <UserForm :user="user" :newUser="false" />
        <div class="form__section">
            <h3>Удаление пользователя</h3>
            <button @click="deleteUser">Удалить пользователя</button>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import UserForm from '../../components/UserForm.vue'
export default {
    name: 'UserCard',
    components:{
        UserForm
    },
    data(){
        return {
            user: {
                name: null,
                email: null,
                level: null,
            },
            navlinks: [
                {
                    title: 'Назад к списку пользователей',
                    route: '/users',
                    icon: 'arrow-left'
                }
            ]
        }
    },
    methods: {
        ...mapGetters({
            getUsers: 'GetUsers'
        }),
        ...mapActions({
            deleteAction: 'UserDeleteAction'
        }),
        deleteUser(){
            this.deleteAction(this.user.id);
        }
    },
    mounted(){
        const users = this.getUsers();
        if(users){
            const id = this.$route.params.id;
            this.user = users.find(user => user.id == id);
        }else{
            this.$router.push('/');
        }
    }
}
</script>