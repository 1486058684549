<template>
    <div class="form">
        <div class="form__row">
            <div class="form__label">Имя:</div>
            <div class="form__input"><input type="text" v-model="name"></div>
        </div>
        <div class="form__row">
            <div class="form__label">Email:</div>
            <div class="form__input"><input type="text" v-model="email"></div>
        </div>
        <div v-if="!newUser" class="form__row">
            <div class="form__label">Lavel:</div>
            <div class="form__input">
                <button @click="down">-</button>
                <input type="text" v-model="level" class="_small">
                <button @click="up">+</button>
            </div>
        </div>
        <div v-if="newUser" class="form__row">
            <div class="form__label">Пароль:</div>
            <div class="form__input">
                <input type="text" v-model="password">
            </div>
        </div>
        <div class="form__row">
            <div class="form__label"></div>
            <div class="form__input">
                <button v-if="newUser" @click="register">Сохранить</button>
                <button v-else @click="updateUser">Сохранить изменения</button>
                <router-link to="/users">
                    <button>Отмена</button>
                </router-link>
            </div>
        </div>
        <div v-if="!newUser" class="form__section">
            <h3>Смена пароля</h3>
            <div class="form__row">
                <div class="form__label">Пароль:</div>
                <div class="form__input">
                    <input type="text" v-model="password">
                    <button @click="updatePassword">Сохранить новый пароль</button>
                </div>
            </div>
        </div>
        <div v-if="!newUser" class="form__section">
            <h3>Personal Access Token</h3>
            <button @click="logoutUser">Сбросить токен</button>
        </div>
    </div>

</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: 'UserForm',
    props: {
        user: Object,
        newUser: Boolean
    },
    data(){
        return {
            name: this.user.name,
            email: this.user.email,
            level: this.user.level,
            password: null
        }
    },
    methods:{
        ...mapActions({
            updateAction: 'UpdateUserAction',
            logoutUserAction: 'LogoutUserAction',
            registerAction: 'RegisterAction',
            updatePasswordAction: 'UserPasswordUpdateAction'
        }),
        up(){
            if(this.level < 3) this.level++
        },
        down(){
            if(this.level > 0) this.level--
        },
        updateUser(){
            const payload = {
                id: this.user.id,
                name: this.name,
                email: this.email,
                level: this.level
            }
            this.updateAction(payload);
        },
        logoutUser(){
            const id = this.user.id.toString();
            this.logoutUserAction(id);
        },
        register(){
            const payload = {
                name: this.name,
                email: this.email,
                password: this.password
            }
            this.registerAction(payload);
        },
        updatePassword(){
            const payload = {
                id: this.user.id,
                password: this.password
            }
            this.updatePasswordAction(payload);
            this.password = "";
        }
    },
    watch: {
        user: function(val){
            this.name = val.name;
            this.email = val.email;
            this.level = val.level;
        }
    }
}
</script>
